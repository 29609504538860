import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  USERFETCH_SUCCESS,
  USERUPDATE_SUCCESS,
  USERUPDATE_ERROR,
  SIGNOUT,
} from "../../types";

const AuthReducer = (state: any, action: any) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
    case SIGNIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        auth: true,
        user: action.payload.data,
        message: null,
      };
    case SIGNUP_ERROR:
    case SIGNIN_ERROR:
    case SIGNOUT:
    case USERUPDATE_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        user: null,
        auth: null,
        message: action.payload,
      };
    case USERFETCH_SUCCESS:
      return {
        ...state,
        auth: true,
        user: action.payload,
      };
    case USERUPDATE_ERROR:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
