import React from "react";

interface SubmitInputProps {
  label: string;
  type: string;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  focus?: boolean;
}

const SubmitInput: React.FC<SubmitInputProps> = ({
  label,
  type,
  id,
  name,
  placeholder,
  value,
  onChange,
  focus = false,
}) => {
  return (
    <div className="relative">
      <label className="text-2xl" htmlFor={id}>
        {label}
      </label>
      <input
        className="w-full border border-gray-600 p-3 text-gray-600 text-xl h-10"
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={focus}
      />
    </div>
  );
};

export default SubmitInput;
