import React from "react";

interface IconArrowRightProps {
  size: number;
}

const IconArrowRight: React.FC<IconArrowRightProps> = ({ size }) => {
  const sizeCN = `w-${size} h-${size}`;
  return (
    <svg
      className={sizeCN}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
};

export default IconArrowRight;
