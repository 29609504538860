import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import AuthContext from "../context/auth/auth-context";

// https://medium.com/@pierrehedkvist/react-router-redirect-with-typescript-90c08e6ab387
const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  const authContext = useContext(AuthContext);
  const { auth, fetchUserFn } = authContext;

  // auto-login if token exist
  useEffect(() => {
    fetchUserFn();
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
