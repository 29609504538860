import React, { useReducer } from "react";

import AlertReducer from "./alert-reducer";
import AlertContext from "./alert-context";

import { SHOW_ALERT, HIDE_ALERT } from "../../types";

const AlertState = (props: any) => {
  const initialState = {
    alert: null,
  };

  const [state, dispatch] = useReducer(AlertReducer, initialState);

  // Fns
  const showAlertFn = (message: any, category: any) => {
    dispatch({
      type: SHOW_ALERT,
      payload: {
        message,
        category,
      },
    });

    setTimeout(() => {
      dispatch({
        type: HIDE_ALERT,
      });
    }, 5000);
  };

  return (
    <AlertContext.Provider
      value={{
        alert: state.alert,
        showAlertFn,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
