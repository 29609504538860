import React, { useReducer } from "react";

import MessageContext from "./message-context";
import MessageReducer from "./message-reducer";
import {
  NEXTPAGE_MESSAGES,
  PREVIOUSPAGE_MESSAGES,
  CLEANPAGES_MESSAGES,
} from "../../types";

const MessageState = (props: any) => {
  const initialState = {
    pages: [],
    filters: [],
  };

  // dispatch to actions
  const [state, dispatch] = useReducer(MessageReducer, initialState);

  const nextPagesFn = (page: any) => {
    dispatch({
      type: NEXTPAGE_MESSAGES,
      payload: page,
    });
  };

  const previousPageFn = () => {
    dispatch({
      type: PREVIOUSPAGE_MESSAGES,
    });
  };

  const cleanPagesFn = () => {
    dispatch({
      type: CLEANPAGES_MESSAGES,
    });
  };

  return (
    <MessageContext.Provider
      value={{
        pages: state.pages,
        filters: state.filters,
        nextPagesFn,
        previousPageFn,
        cleanPagesFn,
      }}
    >
      {props.children}
    </MessageContext.Provider>
  );
};

export default MessageState;
