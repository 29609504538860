import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import DateRangeBtn from "../../components/forms/DateRangeBtn";
import SubmitBtn from "../../components/forms/SubmitBtn";
import IconClose from "../../components/icons/IconClose";

import { setUrlParams } from "../../helpers/url-params";

import ShipmentContext from "../../context/shipments/shipment-context";

const FilterStats = (props: any) => {
  const { showMobileFilter } = props;
  const shipmentContext = useContext(ShipmentContext);
  const { cleanPagesFn } = shipmentContext;

  const location = useLocation();
  const history = useHistory();

  const date = new Date();
  const lastWeekDate = new Date(date.setDate(date.getDate() - 7));

  const [filters, setFilters] = useState({
    dateStart: lastWeekDate,
    dateEnd: new Date(),
  });

  const { dateStart, dateEnd } = filters;

  // const onChange = (e: any) => {
  //   console.log(e);
  //   setFilters({
  //     ...filters,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const onChangeDateRange = (dateRange: any) => {
    const { startDate, endDate } = dateRange;
    setFilters({
      ...filters,
      dateStart: startDate,
      dateEnd: endDate,
    });
  };

  const onClick = async (e: any) => {
    cleanPagesFn();
    setUrlParams(location, history, filters);
    showMobileFilter();
  };

  return (
    <div className="flex flex-col space-y-12 w-full py-4 px-12 xl:px-4">
      <div className="flex">
        <h2 className="flex-grow text-center font-bold">FILTROS</h2>
        <button
          className="block xl:hidden hover:bg-gray-400 rounded-lg px-2"
          onClick={showMobileFilter}
        >
          <IconClose size={9} />
        </button>
      </div>

      <DateRangeBtn
        id="dates"
        label="RANGO DE FECHA"
        onChangeDateRange={onChangeDateRange}
        startDate={dateStart}
        endDate={dateEnd}
      />

      <SubmitBtn name="FILTRAR" onClick={onClick} />
    </div>
  );
};

export default FilterStats;
