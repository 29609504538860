import React, { useState, useContext, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "../icons/Logo";
import SwitchBtn from "../forms/SwitchBtn";
import IconUser from "../icons/IconUser";
import IconMenuMobile from "../icons/IconMenuMobile";

import AuthContext from "../../context/auth/auth-context";
import DarkModeContext from "../../context/dark-mode/dark-mode-context";

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const authContext = useContext(AuthContext);
  const { signOutFn } = authContext;

  const [showProfile, setShowProfile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const onClickProfile = () => {
    setShowProfile(!showProfile);
  };

  const onClickMenu = () => {
    setShowMenu(!showMenu);
  };

  const onClickSignOut = () => {
    onClickProfile();
    signOutFn();
  };

  let location = useLocation();

  const menuOptions = [
    { route: "/shipments", name: "Envíos" },
    { route: "/messages", name: "Mensajes" },
    { route: "/logs", name: "Registros" },
    { route: "/stats", name: "Estadísticas" },
  ];

  const showNavBar =
    location.pathname === "/" || location.pathname === "/signup"
      ? "hidden"
      : "block";

  return (
    <nav className={`flex-1 bg-gray-800 ${showNavBar}`}>
      <div className="max-w-full xl:max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-2 sm:py-6 lg:py-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* <!-- Mobile menu button--> */}
            <BtnShowMenuMobile onClickMenu={onClickMenu} />
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center h-8 w-auto">
              <div className="h-16 pt-0 sm:pt-3 w-auto">
                <Logo size={16} />
              </div>
            </div>
            <div className="hidden lg:block px-6 py-2 rounded-md text-white">
              <span className="text-3xl font-semibold">mailer</span>
              <span className="text-2xl font-normal">2x3</span>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <Menu
                  menuOptions={menuOptions}
                  currentURL={location.pathname}
                />
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* <!-- Profile dropdown --> */}
            <div className="ml-3 relative">
              <BtnShowMenuUser onClickProfile={onClickProfile} />

              {/* <!-- Profile dropdown panel, show/hide based on dropdown state. --> */}
              <div
                className={`${
                  showProfile ? "block" : "hidden"
                } origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <Link
                  to={"/profile"}
                  className="block px-4 py-2 text-2xl text-gray-700 hover:bg-gray-100 w-full text-left"
                  role="menuitem"
                  onClick={onClickProfile}
                >
                  Tu perfil
                </Link>
                <button
                  className="block px-4 py-2 text-2xl text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={() => onClickSignOut()}
                  role="menuitem"
                >
                  Cerrar Sesión
                </button>
              </div>
            </div>
            <div className="ml-6 relative">
              {/* <!-- Dark theme --> */}
              <BtnSetDarkMode />
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
      <div className={`${showMenu ? "block" : "hidden"} sm:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <MenuMobile
            menuOptions={menuOptions}
            currentURL={location.pathname}
            onClick={onClickMenu}
          />
        </div>
      </div>
    </nav>
  );
};

interface MenuProps {
  menuOptions: MenuItemProps[];
  currentURL: string;
}

interface MenuItemProps {
  name: string;
  route: string;
}

const Menu: React.FC<MenuProps> = ({ menuOptions, currentURL }) => {
  return (
    <Fragment>
      {menuOptions.map((menu: MenuItemProps) => (
        <Link
          key={menu.name}
          to={menu.route}
          className={
            menu.route === currentURL
              ? "px-3 py-2 rounded-md text-2xl font-normal text-white bg-gray-900"
              : "px-3 py-2 rounded-md text-2xl font-normal text-gray-300 hover:text-white hover:bg-gray-700"
          }
        >
          {menu.name}
        </Link>
      ))}
    </Fragment>
  );
};

interface MenuMobileProps {
  menuOptions: MenuMobileItemProps[];
  currentURL: string;
  onClick: any;
}

interface MenuMobileItemProps {
  name: string;
  route: string;
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  menuOptions,
  currentURL,
  onClick,
}) => {
  return (
    <Fragment>
      {menuOptions.map((menu: MenuMobileItemProps) => (
        <Link
          key={menu.name}
          to={menu.route}
          className={
            menu.route === currentURL
              ? "block px-3 py-2 rounded-md text-2xl font-normal text-white bg-gray-900"
              : "block px-3 py-2 rounded-md text-2xl font-normal text-gray-300 hover:text-white hover:bg-gray-700"
          }
          onClick={onClick}
        >
          {menu.name}
        </Link>
      ))}
    </Fragment>
  );
};

interface BtnShowMenuMobileProps {
  onClickMenu: any;
}

const BtnShowMenuMobile: React.FC<BtnShowMenuMobileProps> = ({
  onClickMenu,
}) => {
  return (
    <button
      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
      aria-expanded="false"
      onClick={onClickMenu}
    >
      <span className="sr-only">Open main menu</span>
      <IconMenuMobile size={9} />
    </button>
  );
};

interface BtnShowMenuUserProps {
  onClickProfile: any;
}

const BtnShowMenuUser: React.FC<BtnShowMenuUserProps> = ({
  onClickProfile,
}) => {
  return (
    <button
      className="bg-gray-800 flex text-3xl rounded-full text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
      id="user-menu"
      aria-haspopup="true"
      onClick={onClickProfile}
    >
      <span className="sr-only">Open user menu</span>
      <IconUser size={10} />
    </button>
  );
};

interface BtnSetDarkModeProps {}

const BtnSetDarkMode: React.FC<BtnSetDarkModeProps> = () => {
  const darkModeContext = useContext(DarkModeContext);
  const { isDarkMode, loadDarkModeFn, handlerDarkModeFn } = darkModeContext;

  useEffect(() => {
    loadDarkModeFn();
    // eslint-disable-next-line
  }, []);

  const onClickDark = () => {
    handlerDarkModeFn(isDarkMode);
  };

  return (
    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
      <SwitchBtn isON={isDarkMode} onClick={onClickDark} />
    </div>
  );
};

export default Header;
