import {
  NEXTPAGE_MESSAGES,
  PREVIOUSPAGE_MESSAGES,
  CLEANPAGES_MESSAGES,
} from "../../types";

const MessageReducer = (state: any, action: any) => {
  switch (action.type) {
    case NEXTPAGE_MESSAGES:
      return {
        ...state,
        pages: [...state.pages, action.payload],
      };
    case PREVIOUSPAGE_MESSAGES:
      return {
        ...state,
        pages: state.pages.slice(0, state.pages.length - 1),
      };
    case CLEANPAGES_MESSAGES:
      return {
        ...state,
        pages: [],
      };
    default:
      return state;
  }
};

export default MessageReducer;
