import axiosClient from "../helpers/axios";
import tokenAuth from "../helpers/token";

export async function fetchLogs(key: string, filters: any, page?: any) {
  console.log("fetch logs");
  const searchParams = new URLSearchParams();

  // filters by event
  if (!!filters.event && filters.event !== "empty") {
    key = `${key}/event/${filters.event}`;
  }

  if (!!filters.shipment) {
    // filters by message
    if (!!filters.message) {
      key = `${key}/message/${filters.shipment}/${filters.message}`;
    }
    // filters by shipment
    else {
      key = `${key}/shipment/${filters.shipment}`;
    }
  }

  // filters by date
  if (!!filters.datestart && !!filters.dateend) {
    searchParams.set("datestart", filters.datestart);
    searchParams.set("dateend", filters.dateend);
  }

  // paged
  if (
    !!page.lastKeyP1 &&
    !!page.lastKeyP2 &&
    !!page.lastKeyP3 &&
    !!page.lastTime
  ) {
    searchParams.set("lastkeyp1", page.lastKeyP1);
    searchParams.set("lastkeyp2", page.lastKeyP2);
    searchParams.set("lastkeyp3", page.lastKeyP3);
    searchParams.set("lasttime", page.lastTime);
  }

  let urlParams = searchParams.toString();
  if (!!urlParams) urlParams = `?${urlParams}`;

  try {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);
    const response = await axiosClient.get(`/${key}${urlParams}`);

    if (!response.data.data) return [];

    return Array.isArray(response.data.data)
      ? response.data.data
      : [response.data.data];

    // return response.data.data;
  } catch (err) {
    console.log(err);
    if (err.response === undefined) {
      throw new Error("Error de conexion");
    } else {
      throw new Error(err.response.statusText);
    }
  }
}
