import React, { useReducer } from "react";

import ShipmentContext from "./shipment-context";
import ShipmentReducer from "./shipment-reducer";
import {
  NEXTPAGE_SHIPMENTS,
  PREVIOUSPAGE_SHIPMENTS,
  CLEANPAGES_SHIPMENTS,
} from "../../types";

export interface ShipmentStateProps {}

const ShipmentState = (props: any) => {
  const initialState = {
    pages: [],
    filters: [],
  };

  // dispatch to actions
  const [state, dispatch] = useReducer(ShipmentReducer, initialState);

  const nextPagesFn = (page: any) => {
    dispatch({
      type: NEXTPAGE_SHIPMENTS,
      payload: page,
    });
  };

  const previousPageFn = () => {
    dispatch({
      type: PREVIOUSPAGE_SHIPMENTS,
    });
  };

  const cleanPagesFn = () => {
    dispatch({
      type: CLEANPAGES_SHIPMENTS,
    });
  };

  return (
    <ShipmentContext.Provider
      value={{
        pages: state.pages,
        filters: state.filters,
        nextPagesFn,
        previousPageFn,
        cleanPagesFn,
      }}
    >
      {props.children}
    </ShipmentContext.Provider>
  );
};

export default ShipmentState;
