import React from "react";
import IconArrowLeft from "../icons/IconArrowLeft";
import IconArrowRight from "../icons/IconArrowRight";

interface NextPageBtnProps {
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const NextPageBtn: React.FC<NextPageBtnProps> = ({
  disabled,
  onClick,
}) => {
  return (
    <button
      className="flex items-center transition duration-500 ease-in-out bg-gray-400 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-400 rounded-lg pl-5 shadow"
      onClick={onClick}
      disabled={disabled}
    >
      <span className="text-xl">Siguiente</span>
      <IconArrowRight size={9} />
    </button>
  );
};

interface PreviousPageBtnProps {
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const PreviousPageBtn: React.FC<PreviousPageBtnProps> = ({
  disabled,
  onClick,
}) => {
  return (
    <button
      className="flex items-center transition duration-500 ease-in-out bg-gray-400 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-400 rounded-lg pr-5 shadow"
      onClick={onClick}
      disabled={disabled}
    >
      <IconArrowLeft size={9} />
      <span className="text-xl">Anterior</span>
    </button>
  );
};

interface NumberPageProps {
  number: number;
}

export const NumberPage: React.FC<NumberPageProps> = ({ number }) => {
  return (
    <div className="flex items-center text-xl font-semibold px-2">{`[Pag. ${number}]`}</div>
  );
};
