import {
  NEXTPAGE_SHIPMENTS,
  PREVIOUSPAGE_SHIPMENTS,
  CLEANPAGES_SHIPMENTS,
} from "../../types";

const ShipmentReducer = (state: any, action: any) => {
  switch (action.type) {
    case NEXTPAGE_SHIPMENTS:
      return {
        ...state,
        pages: [...state.pages, action.payload],
      };
    case PREVIOUSPAGE_SHIPMENTS:
      return {
        ...state,
        pages: state.pages.slice(0, state.pages.length - 1),
      };
    case CLEANPAGES_SHIPMENTS:
      return {
        ...state,
        pages: [],
      };
    default:
      return state;
  }
};

export default ShipmentReducer;
