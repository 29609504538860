export const setUrlParams = (location: any, history: any, filters: any) => {
  const searchParams = new URLSearchParams(location.search);
  Object.keys(filters).forEach((key: any) => {
    if ((filters as any)[key] !== null || (filters as any)[key] !== "") {
      switch (key) {
        case "dateStart":
        case "dateEnd":
          searchParams.set(
            key.toLowerCase(),
            (filters as any)[key].toISOString().split("T")[0]
          );
          break;
        default:
          searchParams.set(key.toLowerCase(), (filters as any)[key]);
          break;
      }
    }
    history.push(`?${searchParams.toString()}`);
  });
};

export const getUrlParams = (location: any) => {
  const searchParams = new URLSearchParams(location.search);
  let urlParams: any = {};
  searchParams.forEach((value, key) => {
    urlParams[key] = value;
  });
  return urlParams;
};
