import React from "react";

import { EVENTS_ARRAY, getColor, getNameEs } from "../../helpers/event";

import PercentBar from "../../components/common/PercentBar";
import ChartDoughnut from "../../components/charts/ChartDoughnut";
import ChartBar from "../../components/charts/ChartBar";
import ChartLine from "../../components/charts/ChartLine";

export interface ChartStatsProps {
  data: any[];
}

const ChartStats: React.FC<ChartStatsProps> = ({ data }) => {
  const eventsArray: any = [];

  let totalSend = 0;
  EVENTS_ARRAY.forEach((event: string) => {
    let quantity: number = 0;
    data.forEach((stat: any) => {
      quantity += stat[event] !== undefined ? stat[event] : 0;
    });
    eventsArray.push({
      color: getColor(event),
      event: getNameEs(event),
      quantity,
    });

    if (event === "Send") totalSend = quantity;
  });

  return (
    <div className="justify-center">
      <div className="flex flex-wrap justify-around mb-12 pb-4 shadow-2xl">
        {eventsArray.map((bar: any, index: number) => (
          <div key={index} className="w-4/12 sm:w-1/5 m-4">
            <PercentBar
              color={bar.color}
              event={bar.event}
              completed={Number(
                isNaN((bar.quantity / totalSend) * 100)
                  ? 0
                  : ((bar.quantity / totalSend) * 100).toFixed(2)
              )}
            />
          </div>
        ))}
      </div>
      <div className="mb-12 p-4 shadow-2xl">
        <ChartBar title="Histórico Detallado Barras" data={data} />
      </div>
      <div className="mb-12 p-4 shadow-2xl">
        <ChartLine title="Histórico Detallado Lineas" data={data} />
      </div>
      <div className="mb-12 p-4 shadow-2xl">
        <ChartDoughnut title="Histórico Acumulado" data={data} />
      </div>
    </div>
  );
};

export default ChartStats;
