import React, { Fragment, useState } from "react";

interface SelectBtnProps {
  id: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  list: any[];
  selected: any;
}

const SelectBtn: React.FC<SelectBtnProps> = ({
  id,
  label,
  onChange,
  list,
  selected,
}) => {
  const [showList, setShowList] = useState(false);
  const handlerShowList = () => {
    setShowList(!showList);
  };

  const handlerSelected = (item: any) => {
    onChange(item.id);
    handlerShowList();
  };

  const itemSelected = list.filter((item: any) => item.id === selected)[0];

  return (
    <div>
      <label id={`listbox-${id}-label`} className="block text-2xl">
        {label}
      </label>
      <div className="mt-1 relative">
        <button
          className="w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 text-xl"
          type="button"
          onClick={handlerShowList}
        >
          <span className="flex items-center">
            <span className="block truncate text-gray-800">
              {itemSelected.name}
            </span>
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {/* <!-- Heroicon name: selector --> */}
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>

        <div
          className={`${
            !showList && "hidden"
          } absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg`}
        >
          <ul
            tabIndex={-1}
            role="listbox"
            className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-xl"
          >
            <ListItems
              id={id}
              list={list}
              selected={itemSelected}
              handlerSelected={handlerSelected}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SelectBtn;

interface ListItemsProps {
  id: string;
  list: ItemProps[];
  selected: any;
  handlerSelected: any;
}

interface ItemProps {
  id: string;
  name: string;
}

const ListItems: React.FC<ListItemsProps> = ({
  id,
  list,
  selected,
  handlerSelected,
}) => {
  return (
    <Fragment>
      {list.map((item: ItemProps) => (
        <li
          key={item.id}
          id={`listbox-${id}-item-${item.id}`}
          className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-200"
          onClick={() => handlerSelected(item)}
        >
          <div className="flex items-center">
            <span
              className={`${
                selected.id === item.id && "font-semibold"
              } ml-3 block font-normal truncate`}
            >
              {item.name}
            </span>
          </div>

          <span
            className={`${
              selected.id !== item.id && "hidden"
            } absolute inset-y-0 right-0 flex items-center pr-4`}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </li>
      ))}
    </Fragment>
  );
};
