import axiosClient from "../helpers/axios";
import tokenAuth from "../helpers/token";

export async function fetchStats(key: string, filters: any) {
  console.log("fetch stats");
  const searchParams = new URLSearchParams();

  // filters by date
  if (!!filters.datestart && !!filters.dateend) {
    searchParams.set("datestart", filters.datestart);
    searchParams.set("dateend", filters.dateend);
  }

  let urlParams = searchParams.toString();
  if (!!urlParams) urlParams = `?${urlParams}`;

  try {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);
    const response = await axiosClient.get(`/${key}${urlParams}`);

    if (!response.data.data) return [];

    return Array.isArray(response.data.data)
      ? response.data.data
      : [response.data.data];

    // return response.data.data;
  } catch (err) {
    console.log(err);
    if (err.response === undefined) {
      throw new Error("Error de conexion");
    } else {
      throw new Error(err.response.statusText);
    }
  }
}
