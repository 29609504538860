import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import { fetchShipments } from "../../services/shipment-service";
import { getUrlParams } from "../../helpers/url-params";

import TableShipments from "./TableShipments";
import Alert from "../../components/common/Alert";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import {
  NextPageBtn,
  PreviousPageBtn,
  NumberPage,
} from "../../components/common/PagedBtns";

import AlertContext from "../../context/alerts/alert-context";
import ShipmentContext from "../../context/shipments/shipment-context";

export interface DataShipmentsProps {}

const DataShipments: React.FC<DataShipmentsProps> = () => {
  const alertContext = useContext(AlertContext);
  const { alert, showAlertFn } = alertContext;

  const shipmentContext = useContext(ShipmentContext);
  const { pages, nextPagesFn, previousPageFn } = shipmentContext;

  const location = useLocation();

  const [page, setPage] = useState({
    lastKey: "",
    lastTime: "",
  });

  const date = new Date();
  const lastWeekDate = new Date(date.setDate(date.getDate() - 7));

  const [filters, setFilters] = useState({
    datestart: lastWeekDate.toISOString().split("T")[0],
    dateend: new Date().toISOString().split("T")[0],
    id: "",
  });

  useEffect(() => {
    const urlParams = getUrlParams(location);
    if (
      (urlParams.datestart !== undefined &&
        urlParams.dateend !== undefined &&
        filters.datestart !== urlParams.datestart &&
        filters.dateend !== urlParams.dateend) ||
      urlParams.id !== undefined
    ) {
      setFilters({
        datestart: urlParams.datestart,
        dateend: urlParams.dateend,
        id: urlParams.id,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (pages.length > 0) {
      const lastPage = pages[pages.length - 1];
      setPage({
        lastKey: lastPage.lastKey,
        lastTime: lastPage.lastTime,
      });
    } else if (page.lastKey !== "" && page.lastTime !== "") {
      setPage({
        lastKey: "",
        lastTime: "",
      });
    }
    // eslint-disable-next-line
  }, [pages]);

  const {
    // isLoading,
    isError,
    error,
    resolvedData,
    latestData,
    isFetching,
  } = usePaginatedQuery(["shipments", filters, page], fetchShipments, {
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (isError && error !== null) {
      showAlertFn((error as any).message, "error");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    isFetching && showAlertFn("Cargando Envios", "info");
    // eslint-disable-next-line
  }, [resolvedData, latestData]);

  const nextPage = () => {
    const lastItem = resolvedData[resolvedData.length - 1];
    nextPagesFn({
      lastKey: lastItem.SK.replaceAll("#", ""),
      lastTime: lastItem.timestamp,
    });
  };

  const previousPage = () => {
    previousPageFn();
  };

  return (
    <Fragment>
      {alert && <Alert category={alert.category}>{alert.message}</Alert>}

      {/* Page Buttons */}
      <div className="flex p-1 justify-start md:justify-end xl:px-4 xl:pb-2 w-full">
        <div className="flex-grow pl-4 font-semibold hidden sm:block">
          Listado de Envíos
        </div>
        <PreviousPageBtn disabled={pages.length < 1} onClick={previousPage} />
        <NumberPage number={pages.length + 1} />
        <NextPageBtn
          disabled={
            (!!resolvedData && resolvedData.length < 25) || !resolvedData
          }
          onClick={nextPage}
        />
      </div>

      {/* Table */}
      <div className="p-1 xl:px-4 xl:py-2 w-full">
        {!isFetching && !!resolvedData && (
          <TableShipments data={resolvedData} />
        )}
      </div>

      {/* Scroll top */}
      <div className="flex p-1 justify-start md:justify-end xl:px-4 py-4 w-full">
        <ScrollTopBtn />
      </div>
    </Fragment>
  );
};

export default DataShipments;
