import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// layouts
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import SignIn from "./domain/auth/SignIn";
import SignUp from "./domain/auth/SignUp";
import Profile from "./domain/auth/Profile";
import Shipments from "./domain/shipments";
import Messages from "./domain/messages";
import Logs from "./domain/logs";
import Stats from "./domain/stats";

import DarkModeState from "./context/dark-mode/dark-mode-state";
import AlertState from "./context/alerts/alert-state";
import AuthState from "./context/auth/auth-state";
import ShipmentState from "./context/shipments/shipment-state";
import MessageState from "./context/messages/message-state";
import LogState from "./context/logs/log-state";

import PrivateRoute from "./routes/private-route";

function App() {
  return (
    <DarkModeState>
      <AlertState>
        <AuthState>
          <ShipmentState>
            <MessageState>
              <LogState>
                <Router>
                  <Header />
                  <Switch>
                    <Route exact path="/" component={SignIn} />
                    <Route exact path="/signup" component={SignUp} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute
                      exact
                      path="/shipments"
                      component={Shipments}
                    />
                    <PrivateRoute exact path="/messages" component={Messages} />
                    <PrivateRoute exact path="/logs" component={Logs} />
                    <PrivateRoute exact path="/stats" component={Stats} />
                  </Switch>
                </Router>
                <Footer />
              </LogState>
            </MessageState>
          </ShipmentState>
        </AuthState>
      </AlertState>
    </DarkModeState>
  );
}

export default App;
