import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import Alert from "../../components/common/Alert";
import SubmitInput from "./components/SubmitInput";
import SubmitBtn from "./components/SubmitBtn";
import Logo from "../../components/icons/Logo";

import AlertContext from "../../context/alerts/alert-context";
import AuthContext from "../../context/auth/auth-context";

export interface SignInProps {}

const SignIn: React.FC<SignInProps> = (props: any) => {
  // get context values
  const alertContext = useContext(AlertContext);
  const { alert, showAlertFn } = alertContext;

  const authContext = useContext(AuthContext);
  const { message, auth, signInFn } = authContext;

  // access to shipments or alert message
  useEffect(() => {
    if (auth) props.history.push("/shipments");
    if (message) showAlertFn(message.message, message.category);
    // eslint-disable-next-line
  }, [message, auth, props.history]);

  // state start session
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  // get user info
  const { email, password } = user;

  const onChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  // login user
  const onClick = () => {
    // validate empty fields
    if (email.trim() === "" || password.trim() === "") {
      showAlertFn("Todos los campos son obligatorios", "error");
      return;
    }

    // go to action (reducer)
    signInFn({ email, password });
  };

  const handlerEnter = (e: any) => {
    if (e.key === "Enter") onClick();
  };

  return (
    <div
      onKeyDown={handlerEnter}
      className="flex bg-gray-700 min-h-screen items-center justify-center"
    >
      {alert ? <Alert category={alert.category}>{alert.message}</Alert> : null}
      <div className="py-20 px-12 max-w-3xl w-11/12 bg-white rounded-2xl shadow-xl">
        <div className="flex items-center mb-8">
          <div className="hidden sm:block">
            <Logo size={24} />
          </div>

          <h1 className="ml-0 sm:ml-6 mb-2 font-black m-0 text-gray-500 text-5xl">
            INICIAR SESION
          </h1>
        </div>

        <SubmitInput
          type="email"
          id="email"
          name="email"
          placeholder="Tu email"
          value={email}
          onChange={onChange}
          focus={true}
        >
          Email
        </SubmitInput>

        <SubmitInput
          type="password"
          id="password"
          name="password"
          placeholder="Tu Password"
          value={password}
          onChange={onChange}
        >
          Password
        </SubmitInput>

        <SubmitBtn name="INICIAR SESION" onClick={onClick} />

        <Link to={"/signup"} className="mt-8 block opacity-70">
          Obtener Cuenta
        </Link>
      </div>
    </div>
  );
};

export default SignIn;
