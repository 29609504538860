import React from "react";

import Modal from "../../components/common/Modal";
import { getNameEs } from "../../helpers/event";
import { formatDates } from "../../helpers/dates";

const DetailLog = (props: any) => {
  const { log, closeDetail } = props;
  return (
    <Modal title={"Detalle del Envío"} handlerClose={closeDetail}>
      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">ID. ENVIO: </div>
            <div className="flex-grow text-left">{log.shipmentId}</div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">ID. MENSAJE: </div>
            <div className="flex-grow text-left">{log.messageId}</div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">FECHA DE ENVIO: </div>
            <div className="flex-grow text-left">
              {formatDates(log.timestamp)}
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2 w-70 sm:w-full">
            <span className="flex-shrink font-bold">DE: </span>
            <span className="flex-grow text-left truncate">{log.from}</span>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2 w-70 sm:w-full">
            <span className="flex-shrink font-bold">PARA: </span>
            <span className="flex-grow text-left truncate">{log.to}</span>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">ASUNTO: </div>
            <div className="flex-grow text-left">{log.subject}</div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2 w-70 sm:w-full">
            <span className="flex-shrink font-bold">ESTADO: </span>
            <span className="flex-grow text-left truncate">
              {getNameEs(log.event)}
            </span>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2 w-70 sm:w-full">
            <span className="flex-shrink font-bold">DETALLE DEL ESTADO: </span>
            <span className="flex-grow text-left truncate">{log.detail}</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailLog;
