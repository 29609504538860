import { DARKMODE_LOAD, DARKMODE_HANDLER } from "../../types";

const DarkModeReducer = (state: any, action: any) => {
  switch (action.type) {
    case DARKMODE_LOAD:
      return {
        ...state,
        isDarkMode: action.payload,
      };
    case DARKMODE_HANDLER:
      !action.payload
        ? localStorage.setItem("dark-mode", "true")
        : localStorage.removeItem("dark-mode");
      return {
        ...state,
        isDarkMode: !action.payload,
      };
    default:
      return state;
  }
};

export default DarkModeReducer;
