import React, { useState, useEffect, useRef } from "react";

import { DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

interface DateRangeProps {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
  onChangeDateRange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateRange: React.FC<DateRangeProps> = ({
  id,
  label,
  startDate,
  endDate,
  onChangeDateRange,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const onClickShowDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  return (
    <div className="relative">
      <label className="text-2xl" htmlFor={id}>
        {label}
      </label>
      <div className="flex w-full border border-gray-600 dark:border-white">
        <input
          className="flex-grow border p-3 text-gray-600 text-xl h-10 mr-2 truncate"
          readOnly={true}
          type="text"
          id={id}
          placeholder="Seleccione un rango"
          value={`${startDate.toISOString().split("T")[0]} al ${
            endDate.toISOString().split("T")[0]
          }`}
        />
        <button onClick={onClickShowDatePicker}>
          <svg
            className="pb-1 pr-1 w-10 h-10"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </button>
      </div>

      {showDatePicker ? (
        <DateRangePickerEl
          startDate={startDate}
          endDate={endDate}
          setShowDatePicker={setShowDatePicker}
          onChangeDateRange={onChangeDateRange}
        />
      ) : null}
    </div>
  );
};

// https://dev.to/vibhanshu909/click-outside-listener-for-react-components-in-10-lines-of-code-4gjo
// https://www.cluemediator.com/detect-click-outside-a-react-component-using-react-hooks
const DateRangePickerEl = (props: any) => {
  const { startDate, endDate, setShowDatePicker, onChangeDateRange } = props;

  const clickListener = (e: MouseEvent) => {
    if (ref.current && !(ref.current! as any).contains(e.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    // Hide left column in mobile
    const rangesOptionsDiv = document.getElementsByClassName(
      "rdrDefinedRangesWrapper"
    );
    if (rangesOptionsDiv.length > 0 && window.innerWidth <= 640)
      rangesOptionsDiv[0].classList.add("hidden");

    // Attach the listeners on component mount.
    document.addEventListener("click", clickListener);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("click", clickListener);
    };
    // eslint-disable-next-line
  }, []);

  const ref = useRef(null);

  const rangeDate = {
    startDate,
    endDate,
    key: "selection",
  };

  const onChange = (dateRange: any) => {
    const dateRangeSelection = dateRange[0];
    delete dateRangeSelection.key;
    onChangeDateRange(dateRangeSelection);
  };

  return (
    <div className="absolute z-10" ref={ref}>
      <DateRangePicker
        className="shadow-lg text-gray-800"
        onChange={(item: any) => onChange([item.selection])}
        ranges={[rangeDate]}
        showSelectionPreview={true}
        locale={es}
        months={1}
        direction="horizontal"
      />
    </div>
  );
};

export default DateRange;
