import React from "react";

interface SubmitInputProps {
  children: React.ReactNode;
  type: string;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  focus?: boolean;
}

const SubmitInput: React.FC<SubmitInputProps> = ({
  children,
  type,
  id,
  name,
  placeholder,
  value,
  onChange,
  disabled = false,
  focus = false,
}) => {
  return (
    <div className="flex flex-col sm:flex-row mb-8 justify-start sm:items-center text-gray-700">
      <label className="flex-none sm:w-40" htmlFor={id}>
        {children}
      </label>
      <input
        className="flex-grow border p-4"
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoFocus={focus}
      />
    </div>
  );
};

export default SubmitInput;
