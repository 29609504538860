import React from "react";
import config from "../../config/config";

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="flex-1 flex bg-gray-800 py-2 px-4 text-xl sm:text-2xl text-white">
      <a className="flex-grow text-left" href="mailto:reidelgp@gmail.com">
        <span>Copyright &copy; </span>
        <span>{`2018-${new Date().getFullYear()} TechBoreal.`}</span>
        <span> Todos los derechos reservados.</span>
      </a>
      <span className="text-right">{`version: ${config.web.version}`}</span>
    </footer>
  );
};

export default Footer;
