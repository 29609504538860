import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import { fetchStats } from "../../services/stats-service";
import { getUrlParams } from "../../helpers/url-params";

import ChartStats from "./ChartStats";
import Alert from "../../components/common/Alert";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";

import AlertContext from "../../context/alerts/alert-context";

export interface DataStatsProps {}

const DataStats: React.FC<DataStatsProps> = () => {
  const alertContext = useContext(AlertContext);
  const { alert, showAlertFn } = alertContext;

  const location = useLocation();

  const date = new Date();
  const lastWeekDate = new Date(date.setDate(date.getDate() - 7));

  const [filters, setFilters] = useState({
    datestart: lastWeekDate.toISOString().split("T")[0],
    dateend: new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    const urlParams = getUrlParams(location);
    if (
      urlParams.datestart !== undefined &&
      urlParams.dateend !== undefined &&
      filters.datestart !== urlParams.datestart &&
      filters.dateend !== urlParams.dateend
    ) {
      setFilters({
        datestart: urlParams.datestart,
        dateend: urlParams.dateend,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  const {
    // isLoading,
    isError,
    error,
    resolvedData,
    // latestData,
    isFetching,
  } = usePaginatedQuery(["stats", filters], fetchStats, {
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (isError && error !== null) {
      showAlertFn((error as any).message, "error");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    isFetching && showAlertFn("Cargando Estadisticas", "info");
    // eslint-disable-next-line
  }, [resolvedData]);

  return (
    <Fragment>
      {alert && <Alert category={alert.category}>{alert.message}</Alert>}

      {/* Page Buttons */}
      <div className="flex p-1 justify-start md:justify-end xl:px-4 xl:pb-2 w-full">
        <div className="flex-grow pl-4 font-semibold hidden sm:block">
          Estadísticas
        </div>
      </div>

      {/* Chart */}
      <div className="p-1 xl:px-4 xl:py-2 w-full">
        {!isFetching && !!resolvedData && <ChartStats data={resolvedData} />}
      </div>

      {/* Scroll top */}
      <div className="flex p-1 justify-start md:justify-end xl:px-4 py-4 w-full">
        <ScrollTopBtn />
      </div>
    </Fragment>
  );
};

export default DataStats;
