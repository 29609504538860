export const getColor = (event: string) => {
  switch (event) {
    case "Send":
      return "indigo-300";
    case "Delivery":
      return "green-400";
    case "Open":
      return "blue-500";
    case "Complaint":
      return "yellow-300";
    case "BounceTransient":
      return "red-300";
    case "BounceUndetermined":
      return "red-400";
    case "BouncePermanent":
      return "red-500";
    case "Reject":
      return "red-600";
    default:
      return "transparent";
  }
};

export const getColorHEX = (event: string) => {
  switch (event) {
    case "Send":
      return "#a5b4fc";
    case "Delivery":
      return "#34d399";
    case "Open":
      return "#3b82f6";
    case "Complaint":
      return "#fcd34d";
    case "BounceTransient":
      return "#fca5a5";
    case "BounceUndetermined":
      return "#f87171";
    case "BouncePermanent":
      return "#ef4444";
    case "Reject":
      return "#dc2626";
    default:
      return "transparent";
  }
};

export const getNameEs = (event: string) => {
  switch (event) {
    case "Send":
      return "Enviado";
    case "Delivery":
      return "Entregado";
    case "Open":
      return "Abierto";
    case "Complaint":
      return "Protesta";
    case "BounceTransient":
      return "ReboteTrans";
    case "BounceUndetermined":
      return "ReboteIndet";
    case "BouncePermanent":
      return "RebotePerm";
    case "Reject":
      return "Rechazo";
    default:
      return "Desconocido";
  }
};

export const EVENTS_ARRAY = [
  "Send",
  "Delivery",
  "Open",
  "Complaint",
  "BounceTransient",
  "BounceUndetermined",
  "BouncePermanent",
  "Reject",
];

export const EVENTS_ARRAY_ES = [
  "Enviado",
  "Entregado",
  "Abierto",
  "Protesta",
  "ReboteTrans",
  "ReboteIndet",
  "RebotePerm",
  "Rechazo",
];

export const eventsColorArray = () => {
  const arrayColor: string[] = [];
  EVENTS_ARRAY.forEach((event: string) => {
    arrayColor.push(getColorHEX(event));
  });
  return arrayColor;
};
