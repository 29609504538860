import React from "react";

import IconClose from "../icons/IconClose";

interface ModalProps {
  title: string;
  handlerClose: any;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ title, handlerClose, children }) => {
  return (
    <div className="absolute flex items-center justify-center">
      <div className="pt-20 pl-1 pr-3 sm:pl-10 sm:pr-14">
        <div className="transition duration-500 ease-in-out bg-gray-300 dark:bg-gray-700 shadow-2xl flex-row rounded-xl relative">
          <div className="flex transition duration-500 ease-in-out bg-gray-400 dark:bg-gray-800 rounded-t-xl p-4">
            <div className="text-2xl flex-grow">{title}</div>
            <button
              className="hover:bg-gray-400 rounded-xl"
              title="Cerrar"
              onClick={() => handlerClose(null)}
            >
              <IconClose size={8} />
            </button>
          </div>

          <div className="px-2 sm:px-6 py-2">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
