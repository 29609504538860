import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";

import IconView from "../../components/icons/IconView";
import IconDetail from "../../components/icons/IconDetail";
import DetailMessage from "./DetailMessage";

import { formatDates } from "../../helpers/dates";

export interface TableMessagesProps {
  data: any[];
}

const TableMessages: React.FC<TableMessagesProps> = ({ data }) => {
  const [detailMessage, setDetailMessage] = useState(null);
  const handlerDetailMessage = (shipment: any) => {
    !detailMessage ? setDetailMessage(shipment) : setDetailMessage(null);
  };
  return (
    <Fragment>
      {detailMessage && (
        <DetailMessage
          message={detailMessage}
          closeDetail={handlerDetailMessage}
        />
      )}
      <table className="w-full text-lg sm:text-xl xl:text-2xl shadow-2xl">
        <thead>
          <tr className="text-left bg-gray-400 dark:bg-gray-800">
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 pl-4">
              Fecha
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 hidden md:table-cell">
              De
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 hidden md:table-cell">
              Para
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3">
              Asunto
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 text-center">
              Opciones
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((message: any) => {
            return (
              <tr
                key={message.SK}
                className="text-xl leading-6 font-light text-left align-top hover:bg-gray-400 odd:bg-gray-200 dark:odd:bg-gray-600"
              >
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 pl-4 align-middle">
                  {formatDates(message.timestamp)}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 hidden md:table-cell align-middle">
                  {message.from}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 hidden md:table-cell align-middle">
                  {message.to}
                </td>
                <td className="border-solid border-r border-b border-gray-400 dark:border-gray-600 p-2 align-middle">
                  {message.subject}
                </td>
                <td className="border-solid border-r border-b border-gray-400 dark:border-gray-600 p-2">
                  <div className="flex align-middle justify-center">
                    <button
                      className="mx-1"
                      title="Detalles"
                      onClick={() => handlerDetailMessage(message)}
                    >
                      <IconView size={8} />
                    </button>
                    <Link
                      className="mx-1"
                      to={`/logs?shipment=${message.shipmentId}&message=${message.messageId}`}
                      title="Registros"
                    >
                      <IconDetail size={8} />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

export default TableMessages;
