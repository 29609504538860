import React, { useState, useContext, useEffect } from "react";

import Alert from "../../components/common/Alert";
import SubmitInput from "./components/SubmitInput";
import SubmitBtn from "./components/SubmitBtn";

import AuthContext from "../../context/auth/auth-context";
import AlertContext from "../../context/alerts/alert-context";

export interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const authContext = useContext(AuthContext);
  const { message, auth, user, updateProfileFn } = authContext;

  const alertContext = useContext(AlertContext);
  const { alert, showAlertFn } = alertContext;

  useEffect(() => {
    if (message) showAlertFn(message.message, message.category);
    // eslint-disable-next-line
  }, [message]);

  // state start session
  const [userUpdate, setUserUpdate] = useState({
    identity: "",
    username: "",
    address: "",
    email: "",
    oldpassword: "",
    password: "",
    confirm: "",
  });

  useEffect(() => {
    if (user) {
      setUserUpdate({
        identity: user.identity,
        username: user.username,
        address: user.address,
        email: user.email,
        oldpassword: "",
        password: "",
        confirm: "",
      });
    }
    // eslint-disable-next-line
  }, [auth]);

  // get user info
  const {
    identity,
    username,
    address,
    email,
    oldpassword,
    password,
    confirm,
  } = userUpdate;

  const inputsArray = [
    {
      type: "text",
      id: "identity",
      name: "identity",
      placeholder: "Tu Identidad",
      value: identity,
      children: "Identidad",
    },
    {
      type: "email",
      id: "email",
      name: "email",
      placeholder: "Tu email",
      value: email,
      children: "Email",
    },
    {
      type: "text",
      id: "username",
      name: "username",
      placeholder: "Tu Nombre",
      value: username,
      children: "Nombre",
    },
    {
      type: "text",
      id: "address",
      name: "address",
      placeholder: "Tu Dirección",
      value: address,
      children: "Dirección",
    },
    {
      type: "password",
      id: "oldpassword",
      name: "oldpassword",
      placeholder: "Tu Password Actual",
      value: oldpassword,
      children: "Password Actual",
    },
    {
      type: "password",
      id: "password",
      name: "password",
      placeholder: "Tu Password Nuevo",
      value: password,
      children: "Password Nuevo",
    },
    {
      type: "password",
      id: "confirm",
      name: "confirm",
      placeholder: "Repite tu Password Nuevo",
      value: confirm,
      children: "Confirmar Password",
    },
  ];

  const onChange = (e: any) => {
    setUserUpdate({
      ...userUpdate,
      [e.target.name]: e.target.value,
    });
  };

  // user ini session
  const onClick = () => {
    // validate empty fields
    if (
      username.trim() === "" ||
      address.trim() === "" ||
      oldpassword.trim() === "" ||
      password.trim() === "" ||
      confirm.trim() === ""
    ) {
      showAlertFn("Todos los campos son obligatorios", "error");
      return;
    }

    // pass min 6 char
    if (password.length < 6) {
      showAlertFn("El password debe ser de al menos 6 caracteres", "error");
      return;
    }

    // confirm pass
    if (password !== confirm) {
      showAlertFn("Los passwords no son iguales", "error");
      return;
    }

    // go to action (reducer)
    updateProfileFn({
      email,
      username,
      address,
      oldPassword: oldpassword,
      password,
    });
  };

  const handlerEnter = (e: any) => {
    if (e.key === "Enter") onClick();
  };

  return (
    <div
      onKeyDown={handlerEnter}
      className="flex bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white min-h-screen items-center justify-center"
    >
      {alert ? <Alert category={alert.category}>{alert.message}</Alert> : null}
      <div className="py-20 px-12 w-11/12 sm:max-w-7xl bg-white rounded-2xl shadow-xl">
        <div className="flex items-center mb-8">
          <h1 className="mb-2 font-black m-0 text-gray-500 text-5xl">
            ACTUALIZAR DATOS
          </h1>
        </div>

        {inputsArray.map((input: any, index: number) => (
          <SubmitInput
            type={input.type}
            id={input.id}
            name={input.name}
            placeholder={input.placeholder}
            value={input.value}
            onChange={onChange}
            disabled={index === 0 || (index === 1 && true)}
            focus={index === 2 && true}
            key={input.id}
          >
            {input.children}
          </SubmitInput>
        ))}

        <SubmitBtn name="ACTUALIZAR" onClick={onClick} />
      </div>
    </div>
  );
};

export default Profile;
