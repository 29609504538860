import React, { useState, Fragment } from "react";

import IconView from "../../components/icons/IconView";
import DetailLog from "./DetailLog";

import { getColor, getNameEs } from "../../helpers/event";
import { formatDates } from "../../helpers/dates";

export interface TableLogsProps {
  data: any[];
}

const TableLogs: React.FC<TableLogsProps> = ({ data }) => {
  const [detailLog, setDetailLog] = useState(null);
  const handlerDetailLog = (shipment: any) => {
    !detailLog ? setDetailLog(shipment) : setDetailLog(null);
  };
  return (
    <Fragment>
      {detailLog && (
        <DetailLog log={detailLog} closeDetail={handlerDetailLog} />
      )}
      <table className="w-full text-lg sm:text-xl xl:text-2xl shadow-2xl">
        <thead>
          <tr className="text-left bg-gray-400 dark:bg-gray-800">
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 text-center">
              Evento
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3">
              Fecha
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 hidden md:table-cell">
              De
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 hidden md:table-cell">
              Para
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3">
              Asunto
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 text-center">
              Opciones
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((log: any) => {
            const bgColor = `bg-${getColor(log.event)}`;
            return (
              <tr
                key={log.SK}
                className="text-xl leading-6 font-light text-left align-top hover:bg-gray-400 odd:bg-gray-200 dark:odd:bg-gray-600"
              >
                <td className="border-solid border-l border-b border-gray-400 dark:border-gray-600 p-2 text-center align-middle">
                  <span className={`px-3 py-1 rounded-2xl ${bgColor}`}>
                    {getNameEs(log.event)}
                  </span>
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 align-middle">
                  {formatDates(log.timestamp)}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 hidden md:table-cell align-middle">
                  {log.from}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 hidden md:table-cell align-middle">
                  {log.to}
                </td>
                <td className="border-solid border-r border-b border-gray-400 dark:border-gray-600 p-2 align-middle">
                  {log.subject}
                </td>
                <td className="border-solid border-r border-b border-gray-400 dark:border-gray-600 p-2">
                  <div className="flex align-middle justify-center">
                    <button
                      className="mx-1"
                      title="Detalles"
                      onClick={() => handlerDetailLog(log)}
                    >
                      <IconView size={8} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

export default TableLogs;
