import axiosClient from "../helpers/axios";
import tokenAuth from "../helpers/token";

export async function fetchMessages(key: string, filters: any, page?: any) {
  console.log("fetch messages");
  const searchParams = new URLSearchParams();

  // filters by destiny
  if (!!filters.destiny) key = `${key}/destiny/${filters.destiny}`;

  if (!!filters.shipment) {
    delete filters.datestart;
    delete filters.dateend;
    // filters by message
    if (!!filters.id) key = `${key}/id/${filters.shipment}/${filters.id}`;
    // filters by shipment
    else key = `${key}/shipment/${filters.shipment}`;
  }

  // filters by date
  if (!!filters.datestart && !!filters.dateend) {
    searchParams.set("datestart", filters.datestart);
    searchParams.set("dateend", filters.dateend);
  }

  // paged
  if (!!page.lastKeyP1 && !!page.lastKeyP2 && !!page.lastTime) {
    searchParams.set("lastkeyp1", page.lastKeyP1);
    searchParams.set("lastkeyp2", page.lastKeyP2);
    searchParams.set("lasttime", page.lastTime);
  }

  let urlParams = searchParams.toString();
  if (!!urlParams) urlParams = `?${urlParams}`;

  try {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);
    const response = await axiosClient.get(`/${key}${urlParams}`);

    if (!response.data.data) return [];

    return Array.isArray(response.data.data)
      ? response.data.data
      : [response.data.data];

    // return response.data.data;
  } catch (err) {
    console.log(err);
    if (err.response === undefined) {
      throw new Error("Error de conexion");
    } else {
      throw new Error(err.response.statusText);
    }
  }
}
