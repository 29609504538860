import axiosClient from "../helpers/axios";
import tokenAuth from "../helpers/token";

export async function fetchShipments(key: string, filters: any, page?: any) {
  console.log("fetch shipments");
  const searchParams = new URLSearchParams();

  // filters by id
  if (!!filters.id) {
    key = `${key}/id/${filters.id}`;
    delete filters.datestart;
    delete filters.dateend;
  }

  // filters by date
  if (!!filters.datestart && !!filters.dateend) {
    searchParams.set("datestart", filters.datestart);
    searchParams.set("dateend", filters.dateend);
  }

  // paged
  if (!!page.lastKey && !!page.lastTime) {
    searchParams.set("lastkey", page.lastKey);
    searchParams.set("lasttime", page.lastTime);
  }

  let urlParams = searchParams.toString();
  if (!!urlParams) urlParams = `?${urlParams}`;

  try {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);
    const response = await axiosClient.get(`/${key}${urlParams}`);

    if (!response.data.data) return [];

    return Array.isArray(response.data.data)
      ? response.data.data
      : [response.data.data];

    // return response.data.data;
  } catch (err) {
    console.log(err);
    if (err.response === undefined) {
      throw new Error("Error de conexion");
    } else {
      throw new Error(err.response.statusText);
    }
  }
}

// https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
export async function downloadAttachment(attachment: string) {
  console.log("download attachment");

  try {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);
    const response = await axiosClient.get(
      `/shipments/attachment/${attachment}`,
      {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = attachment;
    link.click();
    // link.remove();
  } catch (err) {
    if (err.response === undefined) {
      throw new Error("Error de conexion");
    } else {
      // https://gist.github.com/skratchdot/e095036fad80597f1c1a
      const errString = new TextDecoder().decode(err.response.data);
      const errJson = JSON.parse(errString);
      throw new Error(errJson.message);
    }
  }
}
