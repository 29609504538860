import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import DateRangeBtn from "../../components/forms/DateRangeBtn";
import SelectBtn from "../../components/forms/SelectBtn";
import SubmitBtn from "../../components/forms/SubmitBtn";
import SubmitInput from "../../components/forms/SubmitInput";
import IconClose from "../../components/icons/IconClose";

import { setUrlParams } from "../../helpers/url-params";

import LogContext from "../../context/logs/log-context";

export interface FilterLogsProps {}

const FilterLogs = (props: any) => {
  const { showMobileFilter } = props;
  const logContext = useContext(LogContext);
  const { cleanPagesFn } = logContext;

  const location = useLocation();
  const history = useHistory();

  const date = new Date();
  const lastWeekDate = new Date(date.setDate(date.getDate() - 7));

  const [filters, setFilters] = useState({
    dateStart: lastWeekDate,
    dateEnd: new Date(),
    event: "empty",
    shipment: "",
    message: "",
  });

  const { dateStart, dateEnd, event, shipment, message } = filters;

  const onChange = (e: any) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDateRange = (dateRange: any) => {
    const { startDate, endDate } = dateRange;
    setFilters({
      ...filters,
      dateStart: startDate,
      dateEnd: endDate,
    });
  };

  const onChangeEvent = (event: any) => {
    setFilters({
      ...filters,
      event,
    });
  };

  const onClick = async (e: any) => {
    cleanPagesFn();
    if (location.search.includes("shipment")) location.search = "";
    setUrlParams(location, history, filters);
    showMobileFilter();
  };

  const eventList = [
    { id: "empty", name: "Todos los eventos" },
    { id: "Send", name: "Enviados" },
    { id: "Delivery", name: "Entregados" },
    { id: "Open", name: "Abiertos" },
    { id: "Complaint", name: "Protestas" },
    {
      id: "BounceUndetermined",
      name: "Rebotes Indeterminados",
      selected: false,
    },
    { id: "BouncePermanent", name: "Rebotes Permanentes" },
    { id: "BounceTransient", name: "Rebotes Transitorios" },
    { id: "Reject", name: "Rechazos" },
  ];

  return (
    <div className="flex flex-col space-y-12 w-full py-4 px-12 xl:px-4">
      <div className="flex">
        <h2 className="flex-grow text-center font-bold">FILTROS</h2>
        <button
          className="block xl:hidden hover:bg-gray-400 rounded-lg px-2"
          onClick={showMobileFilter}
        >
          <IconClose size={9} />
        </button>
      </div>

      <DateRangeBtn
        id="dates"
        label="RANGO DE FECHA"
        onChangeDateRange={onChangeDateRange}
        startDate={dateStart}
        endDate={dateEnd}
      />

      <SelectBtn
        id={"events"}
        label={"SELECCIONE EVENTO"}
        onChange={onChangeEvent}
        list={eventList}
        selected={event}
      />

      <SubmitInput
        label="ID. DEL ENVIO"
        type="text"
        id="shipment"
        name="shipment"
        placeholder="Identificación del Envio"
        value={shipment}
        onChange={onChange}
      />

      <SubmitInput
        label="ID. DEL MENSAJE"
        type="text"
        id="message"
        name="message"
        placeholder="Identificación del Mensaje"
        value={message}
        onChange={onChange}
      />

      <SubmitBtn name="FILTRAR" onClick={onClick} />
    </div>
  );
};

export default FilterLogs;
