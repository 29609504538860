// auth
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const USERFETCH_SUCCESS = "USER_SUCCESS";
export const USERUPDATE_SUCCESS = "USERUPDATE_SUCCESS";
export const USERUPDATE_ERROR = "USERUPDATE_ERROR";
export const SIGNOUT = "SIGNOUT";

// alerts
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

// theme
export const DARKMODE_LOAD = "DARKMODE_LOAD";
export const DARKMODE_HANDLER = "DARKMODE_HANDLER";

// shipments
export const NEXTPAGE_SHIPMENTS = "NEXTPAGE_SHIPMENTS";
export const PREVIOUSPAGE_SHIPMENTS = "PREVIOUSPAGE_SHIPMENTS";
export const CLEANPAGES_SHIPMENTS = "CLEANPAGE_SHIPMENTS";

// messages
export const NEXTPAGE_MESSAGES = "NEXTPAGE_MESSAGES";
export const PREVIOUSPAGE_MESSAGES = "PREVIOUSPAGE_MESSAGES";
export const CLEANPAGES_MESSAGES = "CLEANPAGE_MESSAGES";

// logs
export const NEXTPAGE_LOGS = "NEXTPAGE_LOGS";
export const PREVIOUSPAGE_LOGS = "PREVIOUSPAGE_LOGS";
export const CLEANPAGES_LOGS = "CLEANPAGE_LOGS";
