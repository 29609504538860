import React from "react";

interface SubmitBtnProps {
  name: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SubmitBtn: React.FC<SubmitBtnProps> = ({ name, onClick }) => {
  return (
    <div className="flex-grow items-center shadow-xl">
      <button
        className="p-3 text-2xl font-semibold rounded-lg border-none transition duration-500 ease-in-out bg-gray-400 hover:bg-gray-500 dark:bg-gray-800 dark:hover:bg-gray-400 cursor-pointer block w-full"
        onClick={onClick}
      >
        {name}
      </button>
    </div>
  );
};

export default SubmitBtn;
