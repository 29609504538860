import React from "react";

export interface LogoProps {
  size: Number;
}

const Logo: React.FC<LogoProps> = ({ size }) => {
  const sizeCN = `w-${size} h-${size}`;
  return (
    <svg
      className={sizeCN}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
    >
      <circle fill="#4b5563" cx="20" cy="20" r="20" />
      <path
        fill="#E5A72F"
        d="M16.4,20.3L5.9,26.9c0,0-0.2,1.1,1,1l26.1,0c0,0,1.1,0.1,0.9-1l-10.5-6.6l-1.4,0.9c0,0-0.9,0.7-2.1,0.7
	c0,0-1.2,0.1-2-0.5L16.4,20.3z"
      />
      <polygon fill="#F8C33C" points="17.2,20.5 5.9,26.9 5.9,13 " />
      <polygon fill="#F8C33C" points="23.3,20.5 34.1,27 34.1,13.1 " />
      <path
        fill="#C38B2B"
        d="M7.5,12.8H33c0,0,1-0.6,1,0.4l-11.8,8.4c0,0-1,0.8-2.2,0.7c0,0-1.1,0-1.9-0.5L6,13.2
	C6,13.2,5.6,12.8,7.5,12.8z"
      />
      <path
        fill="#FEE148"
        d="M7.5,12.1H33c0,0,1,0,1,1l-11.8,7.4c0,0-1,0.8-2.2,0.7c0,0-1.1,0-1.9-0.5L6,13.1C6,13.1,5.6,12.1,7.5,12.1z"
      />
    </svg>
  );
};

export default Logo;
