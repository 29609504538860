import React, { Fragment } from "react";

interface SwitchBtnProps {
  isON: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

const SwitchBtn: React.FC<SwitchBtnProps> = ({ isON, onClick }) => {
  return (
    <Fragment>
      <input
        type="checkbox"
        id="toggle"
        className={`${
          isON ? "right-0 border-gray-700" : "border-white bg-gray-700"
        } toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer`}
        onClick={onClick}
      />
      <label
        htmlFor="toggle"
        className={`${
          isON ? "bg-gray-700" : "bg-white"
        } toggle-label block overflow-hidden h-6 rounded-full cursor-pointer`}
      ></label>
    </Fragment>
  );
};

export default SwitchBtn;
