import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import DateRangeBtn from "../../components/forms/DateRangeBtn";
import SubmitBtn from "../../components/forms/SubmitBtn";
import SubmitInput from "../../components/forms/SubmitInput";
import IconClose from "../../components/icons/IconClose";

import { setUrlParams } from "../../helpers/url-params";

import MessageContext from "../../context/messages/message-context";

const FilterMessages = (props: any) => {
  const { showMobileFilter } = props;
  const messageContext = useContext(MessageContext);
  const { cleanPagesFn } = messageContext;

  const location = useLocation();
  const history = useHistory();

  const date = new Date();
  const lastWeekDate = new Date(date.setDate(date.getDate() - 7));

  const [filters, setFilters] = useState({
    dateStart: lastWeekDate,
    dateEnd: new Date(),
    destiny: "",
    shipment: "",
    id: "",
  });

  const { dateStart, dateEnd, destiny, shipment, id } = filters;

  const onChange = (e: any) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDateRange = (dateRange: any) => {
    const { startDate, endDate } = dateRange;
    setFilters({
      ...filters,
      dateStart: startDate,
      dateEnd: endDate,
    });
  };

  const onClick = async (e: any) => {
    cleanPagesFn();
    // if (location.search.includes("shipment")) location.search = "";
    setUrlParams(location, history, filters);
    showMobileFilter();
  };

  return (
    <div className="flex flex-col space-y-12 w-full py-4 px-12 xl:px-4">
      <div className="flex">
        <h2 className="flex-grow text-center font-bold">FILTROS</h2>
        <button
          className="block xl:hidden hover:bg-gray-400 rounded-lg px-2"
          onClick={showMobileFilter}
        >
          <IconClose size={9} />
        </button>
      </div>

      <DateRangeBtn
        id="dates"
        label="RANGO DE FECHA"
        onChangeDateRange={onChangeDateRange}
        startDate={dateStart}
        endDate={dateEnd}
      />

      <SubmitInput
        label="DESTINO"
        type="email"
        id="destiny"
        name="destiny"
        placeholder="Email"
        value={destiny}
        onChange={onChange}
      />

      <SubmitInput
        label="ID. DEL ENVIO"
        type="text"
        id="shipment"
        name="shipment"
        placeholder="Identificación del Envio"
        value={shipment}
        onChange={onChange}
      />

      <SubmitInput
        label="ID. DEL MENSAJE"
        type="text"
        id="id"
        name="id"
        placeholder="Identificación del Mensaje"
        value={id}
        onChange={onChange}
      />

      <SubmitBtn name="FILTRAR" onClick={onClick} />
    </div>
  );
};

export default FilterMessages;
