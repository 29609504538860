import React, { Fragment } from "react";

interface PercentBarProps {
  color: string;
  event: string;
  completed: number;
}

const PercentBar: React.FC<PercentBarProps> = ({ color, event, completed }) => {
  const txColor = `text-${color}`;
  const bgColor = `bg-${color}`;

  return (
    <Fragment>
      <div className="flex text-xl">
        <div className="flex-grow">{event}</div>
        <div className={`dark:${txColor}`}>{`${completed}%`}</div>
      </div>
      <div className="h-2 w-full bg-gray-200 rounded-full">
        <div
          style={{ width: `${Math.round(completed)}%` }}
          className={`h-full ${bgColor} text-right rounded-full`}
        ></div>
      </div>
    </Fragment>
  );
};

export default PercentBar;
