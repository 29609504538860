import React, { useState } from "react";

import FilterLogs from "./FilterLogs";
import DataLogs from "./DataLogs";

import SubmitBtn from "../../components/forms/SubmitBtn";

export interface LogsProps {}

const Logs: React.FC<LogsProps> = () => {
  const [showFilter, setShowFilter] = useState(false);

  const onClick = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="flex flex-col">
      {/* main */}
      <main className="flex-grow flex">
        {/* aside bar */}
        <aside
          className={`flex-none ${
            showFilter ? "block w-full" : "hidden"
          } xl:block xl:w-1/5 bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-white min-h-screen`}
        >
          <FilterLogs showMobileFilter={onClick} />
        </aside>
        {/* principal */}
        <div
          className={`flex-grow ${
            showFilter ? "hidden xl:block" : "block"
          } bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white h-full`}
        >
          <div className="flex-col flex h-full">
            {/* mobile filters */}
            <div className="flex-1 p-4 block xl:hidden bg-gray-600">
              <SubmitBtn name="FILTROS" onClick={onClick} />
            </div>
            {/* data area */}
            <div className="flex-grow flex-col h-full p-2 xl:p-4 min-h-screen">
              <DataLogs />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Logs;
