import React from "react";

interface AlertProps {
  children: React.ReactNode;
  category: string;
}

const Alert: React.FC<AlertProps> = ({ children, category }) => {
  let bgColor = "";
  if (category === "success") bgColor = "bg-green-400";
  if (category === "error") bgColor = "bg-red-400";
  if (category === "info") bgColor = "bg-blue-400";

  return (
    <div
      className={`fixed top-0 md:top-12 left-0 md:left-auto md:right-4 w-full md:w-auto py-4 px-12 rounded-2xl font-semibold uppercase text-4xl text-center ${bgColor} text-white`}
    >
      {children}
    </div>
  );
};

export default Alert;
