import React from "react";
import IconArrowUp from "../icons/IconArrowUp";

export interface ScrollTopBtnProps {}

const ScrollTopBtn: React.FC<ScrollTopBtnProps> = () => {
  return (
    <button
      className="flex items-center transition duration-500 ease-in-out bg-gray-400 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-400 rounded-lg px-4 shadow-xl"
      onClick={() => window.scrollTo(0, 0)}
    >
      <IconArrowUp size={9} />
    </button>
  );
};

export default ScrollTopBtn;
