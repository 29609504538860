import React, { useReducer } from "react";
import { queryCache } from "react-query";

import AuthContext from "./auth-context";
import AuthReducer from "./auth-reducer";

import axiosClient from "../../helpers/axios";

import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  USERFETCH_SUCCESS,
  USERUPDATE_SUCCESS,
  USERUPDATE_ERROR,
  SIGNOUT,
} from "../../types";
import tokenAuth from "../../helpers/token";

export interface AuthStateProps {}
const AuthState: React.SFC<AuthStateProps> = (props: any) => {
  const initialState = {
    token: localStorage.getItem("token"),
    auth: null,
    user: null,
    message: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Fns
  const signUpFn = async (data: any) => {
    try {
      const response = await axiosClient.post("/auth/signup", data);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      const alert = {
        message: err.response.data.message,
        category: "error",
      };

      dispatch({
        type: SIGNUP_ERROR,
        payload: alert,
      });
    }
  };

  const signInFn = async (data: any) => {
    try {
      const response = await axiosClient.post("/auth/signin", data);
      dispatch({
        type: SIGNIN_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);

      let message = "Error de conexion. Intente nuevamente.";
      if (err.response && err.response.status === 400)
        message = err.response.data.message;

      const alert = {
        message,
        category: "error",
      };

      dispatch({
        type: SIGNIN_ERROR,
        payload: alert,
      });
    }
  };

  const fetchUserFn = async () => {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);

    try {
      const response = await axiosClient.get("/auth/profile");
      dispatch({
        type: USERFETCH_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      dispatch({
        type: SIGNIN_ERROR,
      });
    }
  };

  const signOutFn = () => {
    // https://github.com/tannerlinsley/react-query/discussions/331
    queryCache.clear();
    dispatch({
      type: SIGNOUT,
    });
  };

  const updateProfileFn = async (data: any) => {
    const token = localStorage.getItem("token");
    if (token) tokenAuth(token);

    try {
      await axiosClient.put("/auth/update", data);
      dispatch({
        type: USERUPDATE_SUCCESS,
      });
    } catch (err) {
      console.log(err);

      let message = "Error de conexion. Intente nuevamente.";
      if (err.response && err.response.status === 400)
        message = err.response.data.message;

      const alert = {
        message,
        category: "error",
      };

      dispatch({
        type: USERUPDATE_ERROR,
        payload: alert,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        auth: state.auth,
        user: state.user,
        message: state.message,
        signUpFn,
        signInFn,
        fetchUserFn,
        signOutFn,
        updateProfileFn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthState;
