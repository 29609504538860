import React, { useReducer } from "react";

import { NEXTPAGE_LOGS, PREVIOUSPAGE_LOGS, CLEANPAGES_LOGS } from "../../types";

import LogContext from "./log-context";
import LogReducer from "./log-reducer";

export interface LogStateProps {}

const LogState = (props: any) => {
  const initialState = {
    pages: [],
    filters: [],
  };

  // dispatch to actions
  const [state, dispatch] = useReducer(LogReducer, initialState);

  const nextPagesFn = (page: any) => {
    dispatch({
      type: NEXTPAGE_LOGS,
      payload: page,
    });
  };

  const previousPageFn = () => {
    dispatch({
      type: PREVIOUSPAGE_LOGS,
    });
  };

  const cleanPagesFn = () => {
    dispatch({
      type: CLEANPAGES_LOGS,
    });
  };

  return (
    <LogContext.Provider
      value={{
        pages: state.pages,
        filters: state.filters,
        nextPagesFn,
        previousPageFn,
        cleanPagesFn,
      }}
    >
      {props.children}
    </LogContext.Provider>
  );
};

export default LogState;
