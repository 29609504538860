import { NEXTPAGE_LOGS, PREVIOUSPAGE_LOGS, CLEANPAGES_LOGS } from "../../types";

const LogReducer = (state: any, action: any) => {
  switch (action.type) {
    case NEXTPAGE_LOGS:
      return {
        ...state,
        pages: [...state.pages, action.payload],
      };
    case PREVIOUSPAGE_LOGS:
      return {
        ...state,
        pages: state.pages.slice(0, state.pages.length - 1),
      };
    case CLEANPAGES_LOGS:
      return {
        ...state,
        pages: [],
      };
    default:
      return state;
  }
};

export default LogReducer;
