import React, { useReducer } from "react";

import DarkModeReducer from "./dark-mode-reducer";
import DarkModeContext from "./dark-mode-context";

import { DARKMODE_LOAD, DARKMODE_HANDLER } from "../../types";

const DarkModeState = (props: any) => {
  const initialState = {
    isDarkMode: false,
  };

  const [state, dispatch] = useReducer(DarkModeReducer, initialState);

  const loadDarkModeFn = () => {
    const payload = localStorage.getItem("dark-mode") === "true";
    if (payload) document.documentElement.classList.add("dark");
    dispatch({
      type: DARKMODE_LOAD,
      payload,
    });
  };

  const handlerDarkModeFn = (isDarkMode: boolean) => {
    document.documentElement.classList.toggle("dark");
    dispatch({
      type: DARKMODE_HANDLER,
      payload: isDarkMode,
    });
  };

  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode: state.isDarkMode,
        loadDarkModeFn,
        handlerDarkModeFn,
      }}
    >
      {props.children}
    </DarkModeContext.Provider>
  );
};

export default DarkModeState;
