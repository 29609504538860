import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import IconView from "../../components/icons/IconView";
import IconDetail from "../../components/icons/IconDetail";
import IconMail from "../../components/icons/IconMail";
import { formatDates } from "../../helpers/dates";
import DetailShipment from "./DetailShipment";

export interface TableShipmentsProps {
  data: any[];
}

const TableShipments: React.FC<TableShipmentsProps> = ({ data }) => {
  const [detailShipment, setDetailShipment] = useState(null);
  const handlerDetailShipment = (shipment: any) => {
    !detailShipment ? setDetailShipment(shipment) : setDetailShipment(null);
  };

  return (
    <Fragment>
      {detailShipment && (
        <DetailShipment
          shipment={detailShipment}
          closeDetail={handlerDetailShipment}
        />
      )}
      <table className="w-full text-lg sm:text-xl xl:text-2xl shadow-2xl">
        <thead>
          <tr className="text-left bg-gray-400 dark:bg-gray-800">
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 pl-4">
              Fecha
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 hidden md:table-cell">
              De
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3">
              Asunto
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 text-center">
              Emails
            </th>
            <th className="border-solid border-b border-gray-400 dark:border-gray-600 p-3 text-center">
              Opciones
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((shipment: any) => {
            return (
              <tr
                key={shipment.shipmentId}
                className="text-xl leading-6 font-light text-left align-top hover:bg-gray-400 odd:bg-gray-200 dark:odd:bg-gray-600"
              >
                <td className="border-solid border-l border-b border-gray-400 dark:border-gray-600 p-2 pl-4 align-middle">
                  {formatDates(shipment.timestamp)}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 hidden md:table-cell align-middle">
                  {shipment.from}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 align-middle">
                  {shipment.subject}
                </td>
                <td className="border-solid border-b border-gray-400 dark:border-gray-600 p-2 text-center align-middle">
                  {shipment.totalMessages}
                </td>
                <td className="border-solid border-r border-b border-gray-400 dark:border-gray-600 p-2">
                  <div className="flex align-middle justify-center">
                    <button
                      className="mx-1"
                      title="Detalles"
                      onClick={() => handlerDetailShipment(shipment)}
                    >
                      <IconView size={8} />
                    </button>
                    <Link
                      className="mx-1"
                      to={`/messages?shipment=${shipment.shipmentId}`}
                      title="Mensajes"
                    >
                      <IconMail size={8} />
                    </Link>
                    <Link
                      className="mx-1"
                      to={`/logs?shipment=${shipment.shipmentId}`}
                      title="Registros"
                    >
                      <IconDetail size={8} />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
};

export default TableShipments;
