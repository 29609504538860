import React, { useEffect, useRef } from "react";
import Chart from "chart.js";

import { dataLine } from "../../helpers/chart";

interface ChartLineProps {
  title: string;
  data: any[];
}

const ChartLine: React.FC<ChartLineProps> = ({ title, data }) => {
  const ref = useRef(null);

  const chartData = dataLine(data);

  useEffect(() => {
    const ctx: any = ref.current;
    new Chart(ctx, {
      type: "line",
      data: chartData,
      options: {
        plugins: {
          title: {
            display: true,
            text: title,
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        responsive: true,
      },
    });
  });

  return (
    <div className="w-full">
      <canvas key={Math.random()} ref={ref} />
    </div>
  );
};

export default ChartLine;
