const config = {
  api: {
    url: process.env.REACT_APP_API,
  },
  web: {
    version: "1.0.1",
  },
};

export default config;
