import React from "react";

interface IconMenuMobileProps {
  size: number;
}

const IconMenuMobile: React.FC<IconMenuMobileProps> = ({ size }) => {
  const sizeCN = `w-${size} h-${size}`;
  return (
    <svg
      className={sizeCN}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );
};

export default IconMenuMobile;
