import React from "react";

interface SubmitBtnProps {
  name: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SubmitBtn: React.FC<SubmitBtnProps> = ({ name, onClick }) => {
  return (
    <div className="flex-grow mb-0 items-center shadow-xl">
      <button
        className="p-6 text-3xl font-semibold mt-8 rounded-lg border-none transition duration-500 ease-in-out bg-gray-600 hover:bg-gray-800 text-white cursor-pointer block w-full"
        onClick={onClick}
      >
        {name}
      </button>
    </div>
  );
};

export default SubmitBtn;
