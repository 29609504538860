import React, { useContext } from "react";
import parser from "html-react-parser";

import Modal from "../../components/common/Modal";
import { downloadAttachment } from "../../services/shipment-service";
import { formatDates } from "../../helpers/dates";

import AlertContext from "../../context/alerts/alert-context";

const DetailShipment = (props: any) => {
  const { shipment, closeDetail } = props;

  const alertContext = useContext(AlertContext);
  const { showAlertFn } = alertContext;

  const handlerDownload = async (attachment: any) => {
    try {
      await downloadAttachment(attachment);
    } catch (err) {
      showAlertFn((err as any).message, "error");
    }
  };
  return (
    <Modal title={"Detalle del Envío"} handlerClose={closeDetail}>
      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">ID. ENVIO: </div>
            <div className="flex-grow text-left">{shipment.shipmentId}</div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">FECHA DE ENVIO: </div>
            <div className="flex-grow text-left">
              {formatDates(shipment.timestamp)}
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2 w-70 sm:w-full">
            <span className="flex-shrink font-bold">DE: </span>
            <span className="flex-grow text-left truncate">
              {shipment.from}
            </span>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="flex flex-col sm:flex-row text-2xl text-justify p-2">
            <div className="flex-shrink font-bold">ASUNTO: </div>
            <div className="flex-grow text-left">{shipment.subject}</div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="text-2xl pt-2 pl-2 pr-2 font-bold">CONTENIDO:</div>
          <div className="text-2xl pb-2 pl-2 pr-2 text-justify overflow-y-auto pb-2">
            {parser(shipment.body)}
          </div>
        </div>
      </div>

      <div>
        <div className="bg-gray-200 dark:bg-gray-600 rounded-xl">
          <div className="text-2xl pt-2 pl-2 pr-2 font-bold rounded-t-xl">
            ADJUNTOS:
          </div>
          <div className="text-2xl pl-2 pr-2 text-justify pb-2 w-60 sm:w-full">
            {shipment.attachments
              .split(";")
              .map((attachment: string, index: number) => {
                return (
                  <div
                    className="bg-gray-300 dark:bg-gray-700 m-1 px-4 py-2 rounded overflow-y-auto truncate"
                    key={index}
                  >
                    <button
                      title="Descargar adjunto"
                      onClick={() => handlerDownload(attachment)}
                    >
                      {attachment}
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailShipment;
