import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePaginatedQuery } from "react-query";

import { fetchLogs } from "../../services/log-service";
import { getUrlParams } from "../../helpers/url-params";

import TableLogs from "./TableLogs";
import Alert from "../../components/common/Alert";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import {
  NextPageBtn,
  PreviousPageBtn,
  NumberPage,
} from "../../components/common/PagedBtns";

import AlertContext from "../../context/alerts/alert-context";
import LogContext from "../../context/logs/log-context";

interface DataLogsProps {}

const DataLogs: React.FC<DataLogsProps> = () => {
  const alertContext = useContext(AlertContext);
  const { alert, showAlertFn } = alertContext;

  const logContext = useContext(LogContext);
  const { pages, nextPagesFn, previousPageFn } = logContext;

  const location = useLocation();

  const [page, setPage] = useState({
    lastKeyP1: "",
    lastKeyP2: "",
    lastKeyP3: "",
    lastTime: "",
  });

  const date = new Date();
  const lastWeekDate = new Date(date.setDate(date.getDate() - 7));

  const [filters, setFilters] = useState({
    datestart: lastWeekDate.toISOString().split("T")[0],
    dateend: new Date().toISOString().split("T")[0],
    event: "empty",
    shipment: "",
    message: "",
  });

  useEffect(() => {
    const urlParams = getUrlParams(location);
    if (
      (urlParams.datestart !== undefined &&
        urlParams.dateend !== undefined &&
        filters.datestart !== urlParams.datestart &&
        filters.dateend !== urlParams.dateend) ||
      urlParams.event !== undefined ||
      urlParams.shipment !== undefined ||
      urlParams.message !== undefined
    ) {
      setFilters({
        datestart: urlParams.datestart,
        dateend: urlParams.dateend,
        event: urlParams.event,
        shipment: urlParams.shipment,
        message: urlParams.message,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (pages.length > 0) {
      const lastPage = pages[pages.length - 1];
      setPage({
        lastKeyP1: lastPage.lastKeyP1,
        lastKeyP2: lastPage.lastKeyP2,
        lastKeyP3: lastPage.lastKeyP3,
        lastTime: lastPage.lastTime,
      });
    } else if (
      page.lastKeyP1 !== "" &&
      page.lastKeyP2 !== "" &&
      page.lastKeyP3 !== "" &&
      page.lastTime !== ""
    ) {
      setPage({
        lastKeyP1: "",
        lastKeyP2: "",
        lastKeyP3: "",
        lastTime: "",
      });
    }
    // eslint-disable-next-line
  }, [pages]);

  const {
    // isLoading,
    isError,
    error,
    resolvedData,
    latestData,
    isFetching,
  } = usePaginatedQuery(["logs", filters, page], fetchLogs, {
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (isError && error !== null) {
      showAlertFn((error as any).message, "error");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    isFetching && showAlertFn("Cargando Registros", "info");
    // eslint-disable-next-line
  }, [resolvedData, latestData]);

  const nextPage = () => {
    const lastItem = resolvedData[resolvedData.length - 1];
    const skArray = lastItem.SK.split("#");
    nextPagesFn({
      lastKeyP1: skArray[1],
      lastKeyP2: skArray[2],
      lastKeyP3: skArray[3],
      lastTime: lastItem.timestamp,
    });
  };

  const previousPage = () => {
    previousPageFn();
  };

  return (
    <Fragment>
      {alert && <Alert category={alert.category}>{alert.message}</Alert>}

      {/* Page Buttons */}
      <div className="flex p-1 justify-start md:justify-end xl:px-4 xl:pb-2 w-full">
        <div className="flex-grow pl-4 font-semibold hidden sm:block">
          Listado de Registros
        </div>
        <PreviousPageBtn disabled={pages.length < 1} onClick={previousPage} />
        <NumberPage number={pages.length + 1} />
        <NextPageBtn
          disabled={!!resolvedData && resolvedData.length < 25}
          onClick={nextPage}
        />
      </div>

      {/* Table */}
      <div className="p-1 xl:px-4 xl:py-2 w-full">
        {!isFetching && !!resolvedData && <TableLogs data={resolvedData} />}
      </div>

      {/* Scroll top */}
      <div className="flex p-1 justify-start md:justify-end xl:px-4 py-4 w-full">
        <ScrollTopBtn />
      </div>
    </Fragment>
  );
};

export default DataLogs;
