import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import Alert from "../../components/common/Alert";
import SubmitInput from "./components/SubmitInput";
import SubmitBtn from "./components/SubmitBtn";
import Logo from "../../components/icons/Logo";

import AlertContext from "../../context/alerts/alert-context";
import AuthContext from "../../context/auth/auth-context";

const SignUp = (props: any) => {
  // get context values
  const alertContext = useContext(AlertContext);
  const { alert, showAlertFn } = alertContext;

  const authContext = useContext(AuthContext);
  const { message, auth, signUpFn } = authContext;

  // access to shipments or alert message
  useEffect(() => {
    if (auth) props.history.push("/shipments");
    if (message) showAlertFn(message.message, message.category);
    // eslint-disable-next-line
  }, [message, auth, props.history]);

  // state start session
  const [user, setUser] = useState({
    identity: "",
    username: "",
    address: "",
    email: "",
    password: "",
    confirm: "",
  });

  // get user info
  const { identity, username, address, email, password, confirm } = user;

  const inputsArray = [
    {
      type: "text",
      id: "identity",
      name: "identity",
      placeholder: "Tu Identidad",
      value: identity,
      children: "Identidad",
    },
    {
      type: "text",
      id: "username",
      name: "username",
      placeholder: "Tu Nombre",
      value: username,
      children: "Nombre",
    },
    {
      type: "text",
      id: "address",
      name: "address",
      placeholder: "Tu Dirección",
      value: address,
      children: "Dirección",
    },
    {
      type: "email",
      id: "email",
      name: "email",
      placeholder: "Tu email",
      value: email,
      children: "Email",
    },
    {
      type: "password",
      id: "password",
      name: "password",
      placeholder: "Tu Password",
      value: password,
      children: "Password",
    },
    {
      type: "password",
      id: "confirm",
      name: "confirm",
      placeholder: "Repite tu Password",
      value: confirm,
      children: "Confirmar Password",
    },
  ];

  const onChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  // user ini session
  const onClick = () => {
    // validate empty fields
    if (
      identity.trim() === "" ||
      username.trim() === "" ||
      address.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      confirm.trim() === ""
    ) {
      showAlertFn("Todos los campos son obligatorios", "error");
      return;
    }

    // pass min 6 char
    if (password.length < 6) {
      showAlertFn("El password debe ser de al menos 6 caracteres", "error");
      return;
    }

    // confirm pass
    if (password !== confirm) {
      showAlertFn("Los passwords no son iguales", "error");
      return;
    }

    // go to action (reducer)
    signUpFn({
      identity,
      username,
      address,
      email,
      password,
    });
  };

  const handlerEnter = (e: any) => {
    if (e.key === "Enter") onClick();
  };

  return (
    <div
      onKeyDown={handlerEnter}
      className="flex bg-gray-700 min-h-screen items-center justify-center"
    >
      {alert ? <Alert category={alert.category}>{alert.message}</Alert> : null}
      <div className="py-20 px-12 max-w-3xl w-11/12 bg-white rounded-2xl shadow-xl">
        <div className="flex items-center mb-8">
          <div className="hidden sm:block">
            <Logo size={24} />
          </div>

          <h1 className="ml-0 sm:ml-6 mb-2 font-black m-0 text-gray-500 text-5xl">
            OBTENER CUENTA
          </h1>
        </div>

        {inputsArray.map((input: any, index: number) => (
          <SubmitInput
            key={input.id}
            type={input.type}
            id={input.id}
            name={input.name}
            placeholder={input.placeholder}
            value={input.value}
            onChange={onChange}
            focus={index === 0 && true}
          >
            {input.children}
          </SubmitInput>
        ))}

        <SubmitBtn name="REGISTRARME" onClick={onClick} />

        <Link to={"/"} className="mt-8 block opacity-70">
          Volver a Iniciar Sesión
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
