import {
  EVENTS_ARRAY,
  EVENTS_ARRAY_ES,
  getNameEs,
  getColorHEX,
  eventsColorArray,
} from "./event";

export const dataDoughnut = (data: any) => {
  const dataArray: any = [0, 0, 0, 0, 0, 0, 0, 0];
  EVENTS_ARRAY.forEach((event: string, index: number) => {
    data.forEach((stat: any) => {
      dataArray[index] += stat[event] !== undefined ? stat[event] : 0;
    });
  });

  return {
    labels: EVENTS_ARRAY_ES,
    datasets: [
      {
        data: dataArray,
        backgroundColor: eventsColorArray(),
        borderWidth: 0,
      },
    ],
  };
};

export const dataBar = (data: any) => {
  const labels: string[] = [];
  const datasets: any = [];

  data.forEach((stat: any) => {
    labels.push(stat.SK.replaceAll("#", ""));
  });

  EVENTS_ARRAY.forEach((event: string) => {
    const dataArray: number[] = [];
    data.forEach((stat: any) => {
      dataArray.push(stat[event] !== undefined ? stat[event] : 0);
    });
    datasets.push({
      label: getNameEs(event),
      backgroundColor: getColorHEX(event),
      data: dataArray,
    });
  });

  return {
    labels,
    datasets,
  };
};

export const dataLine = (data: any) => {
  const labels: string[] = [];
  const datasets: any = [];

  data.forEach((stat: any) => {
    labels.push(stat.SK.replaceAll("#", ""));
  });

  EVENTS_ARRAY.forEach((event: string, index: number) => {
    const dataArray: number[] = [];
    data.forEach((stat: any) => {
      dataArray.push(stat[event] !== undefined ? stat[event] : 0);
    });
    datasets.push({
      label: getNameEs(event),
      backgroundColor: "transparent",
      pointBackgroundColor: getColorHEX(event),
      borderColor: getColorHEX(event),
      borderWidth: 2,
      lineTension: 0,
      data: dataArray,
    });
  });

  return {
    labels,
    datasets,
  };
};
